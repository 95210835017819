import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ContactForm from "../components/ContactForm";
import PageBanner from "../components/PageBanner";
import GoogleMap from "../components/GoogleMap";
import Icon from "../components/Icon";
import HTMLContent from "../components/HTMLContent";
import PhoneNumberLink from "../components/PhoneNumberLink";

const ADDRESS_MAPS_LINK =
  "https://www.google.com/maps/place/Philips+Academy/@35.1692432,-80.8051499,17z/data=!3m1!4b1!4m5!3m4!1s0x88542086ddd90d11:0x660d797c647b6bd9!8m2!3d35.1692388!4d-80.8029559";

export const ContactPageTemplate = (props) => {
  const {
    linkURL,
    locationDescription,
    AddressComponent,
    phone,
    fax,
    email,
    admissionsContactText,
    generalContactText,
    mailingList,
  } = props;
  const contactItems = [
    {
      iconName: "map",
      iconWidth: 26,
      content: (
        <span>
          <a rel="noopener noreferrer" target="_blank" href={ADDRESS_MAPS_LINK}>
            {AddressComponent}
          </a>
        </span>
      ),
    },
    {
      iconName: "phone",
      iconWidth: 26,
      content: (
        <p>
          <PhoneNumberLink phoneNumber={phone} />{" "}
          <span className="info-span">(Phone)</span>
        </p>
      ),
    },
    {
      iconName: "fax",
      iconWidth: 26,
      content: (
        <p>
          <PhoneNumberLink phoneNumber={fax} />{" "}
          <span className="info-span">(Fax)</span>
        </p>
      ),
    },
    {
      iconName: "email",
      iconWidth: 26,
      content: (
        <p>
          <a href={`mailto:${email}`}>{email}</a>
        </p>
      ),
    },
  ];
  return (
    <>
      <PageBanner>
        <h1 className="page-banner-header">CONTACT US</h1>
      </PageBanner>
      <div className="layout-container">
        <div className="top-image-container contact-container">
          <div className="grid-container bg-white">
            <div className="grid-item-2 grid-item">
              <div className="contact-options-container">
                <div>
                  <div>
                    <p>{admissionsContactText}</p>
                  </div>
                  <div className="btn-container">
                    <a
                      className="btn primary-btn btn-1"
                      href={linkURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CONTACT ADMISSIONS
                    </a>
                  </div>
                </div>
                <div className="or">
                  <div className="line">
                    <span />
                  </div>
                  <div className="text">
                    <span>OR</span>
                  </div>
                  <div className="line">
                    <span />
                  </div>
                </div>
                <div>
                  <div>
                    <p>{generalContactText}</p>
                  </div>
                  <div className="btn-container">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-item-2 grid-item">
              {contactItems.map(({ content, iconName, iconWidth }) => {
                return (
                  <div className="contact-item" key={iconName}>
                    <div className="icon">
                      <Icon iconName={iconName} width={iconWidth} />
                    </div>
                    {content}
                  </div>
                );
              })}
              <div>
                <p className="location-info">{locationDescription}</p>
              </div>
            </div>
          </div>
          <div className="map">
            <GoogleMap />
          </div>
        </div>
      </div>
      <div className="sign-up-padding">
        <div className="width-container text-center">
          <h6>{mailingList.description}</h6>
          <a
            href={mailingList.signUpURL}
            target="_blank"
            rel="noopener noreferrer"
            className="btn primary-btn full-width-sm"
          >
            SIGN UP TODAY
          </a>
        </div>
      </div>
    </>
  );
};

ContactPageTemplate.propTypes = {
  linkURL: PropTypes.string,
  heading: PropTypes.string,
  locationDescription: PropTypes.string,
  AddressComponent: PropTypes.node,
  phone: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string,
};

const ContactPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        heading,
        admissionsContactText,
        generalContactText,
        linkURL,
        locationDescription,
        phone,
        fax,
        email,
        mailingList,
      },
    },
  } = data;
  return (
    <Layout
      pageTitle={`Contact Us`}
      ogUrl="/contact/"
      pageDecription="Request information from our admissions office or contact us directly through our web page"
    >
      <ContactPageTemplate
        heading={heading}
        linkURL={linkURL}
        locationDescription={locationDescription}
        admissionsContactText={admissionsContactText}
        generalContactText={generalContactText}
        AddressComponent={
          <HTMLContent
            content={
              data.markdownRemark.fields.frontmatterMarkdownHTML.address
                .markdown
            }
          />
        }
        phone={phone}
        fax={fax}
        email={email}
        mailingList={mailingList}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContactPage;

export const ContactPageQuery = graphql`
  query ContactPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        admissionsContactText
        generalContactText
        linkURL
        locationDescription
        phone
        fax
        email
        mailingList {
          description
          signUpURL
        }
      }
      fields {
        frontmatterMarkdownHTML {
          address {
            markdown
          }
        }
      }
    }
  }
`;
