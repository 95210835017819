import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

const API_KEY = "AIzaSyB-62LCZi72n6u_thaXjLzQ3RiPKprnEzc";
export class MapContainer extends Component {
  render() {
    return (
      <div style={{ height: 350, width: "100%", position: "relative" }}>
        <Map
          google={this.props.google}
          zoom={13}
          style={{ height: 350, width: "100%" }}
          initialCenter={{
            lat: 35.169148,
            lng: -80.803038,
          }}
        >
          <Marker />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({ apiKey: API_KEY })(MapContainer);
