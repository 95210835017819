import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { Formik } from "formik";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Icon from "./Icon";

const GOOGLE_RECAPTCHA_SITE_KEY = "6LfBQdgZAAAAABFSjq9swn4DOzOo8uvx6D0HE3Qf";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function renderContent(result) {
  if (result === "success") {
    return (
      <div className="text-center">
        <div className="success-result-message">
          <div className="add-padding">
            <Icon iconName="checkCircle" width={70} />
          </div>
          <h3>Success!</h3>
        </div>
        <p>
          We have successfully received your message. We will get back to you as
          soon as we can!
        </p>
        <div className="grecaptcha-badge" />
        {/* Including this so that the grecaptcha-badge class doesn't get removed at build time */}
      </div>
    );
  } else if (result === "error") {
    return (
      <div className="text-center">
        <div className="error-result-message">
          <div className="add-padding">
            <Icon iconName="times" width={70} />
          </div>
          <h3>Oh no!</h3>
        </div>
        <p>
          Something went wrong with your submission. Please try again later, or
          contact us through phone or email.
        </p>
      </div>
    );
  }
  return null;
}

const ContactForm = () => {
  const [resultOpen, setResultOpen] = React.useState(false);
  const [formOpen, setFormOpen] = React.useState(false);
  const [submissionResult, setSubmissionResult] = React.useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  function handleFormClose() {
    setFormOpen(false);
  }
  function handleFormOpen() {
    setFormOpen(true);
  }
  function handleResultClose() {
    setResultOpen(false);
  }
  function handleFormExited() {
    if (submissionResult) {
      setResultOpen(true);
    }
  }
  React.useEffect(() => {
    if (submissionResult) {
      handleFormClose();
    }
  }, [submissionResult]);
  return (
    <>
      <Dialog
        open={resultOpen}
        TransitionComponent={Transition}
        fullWidth
        PaperProps={{
          style: {
            maxWidth: 600,
          },
        }}
      >
        <div className="add-padding">
          {renderContent(submissionResult)}
          <div className="close-btn-container">
            <button className="btn white-btn small" onClick={handleResultClose}>
              Close
            </button>
          </div>
        </div>
      </Dialog>
      <button className="btn primary-btn btn-2" onClick={handleFormOpen}>
        SUBMIT GENERAL FORM
      </button>
      <Dialog
        disableBackdropClick
        open={formOpen}
        TransitionComponent={Transition}
        onClose={handleFormClose}
        onExited={handleFormExited}
        fullWidth
        PaperProps={{
          style: {
            maxWidth: 600,
          },
        }}
      >
        <Formik
          initialValues={{ name: "", email: "", message: "" }}
          validate={(values) => {
            const errors = {};
            if (
              /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                values.email
              ) === false
            ) {
              errors.email = "Please enter a valid email";
            }
            if (!values.name) {
              errors.name = "This is a required field";
            }
            if (!values.message) {
              errors.message = "This is a required field";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            executeRecaptcha("CONTACT_US")
              .then((recaptcha) => {
                return fetch("/.netlify/functions/contact", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({ ...values, recaptcha }),
                });
              })
              .then((res) => res.json())
              .then(() => {
                setSubmissionResult("success");
              })
              .catch((e) => {
                console.log(e);
                setSubmissionResult("error");
              })
              .finally(() => {
                resetForm();
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            const disabled = isSubmitting;
            return (
              <div className="contact-us-form-container padding-2">
                <button
                  className="btn close-btn contact-form-btn"
                  onClick={handleFormClose}
                >
                  &times;
                </button>
                <h4>Contact Us</h4>
                <form onSubmit={handleSubmit}>
                  <noscript>
                    <p>Please enable JavaScript to submit this form.</p>
                  </noscript>
                  <input
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    className={`form-input${
                      errors.name && touched.name ? " form-error" : ""
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {errors.name && touched.name && (
                    <span className="form-error-message">{errors.name}</span>
                  )}
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className={`form-input${
                      errors.email && touched.email ? " form-error" : ""
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <span className="form-error-message">{errors.email}</span>
                  )}
                  <textarea
                    type="text"
                    name="message"
                    placeholder="How can we help you?"
                    className={`form-input${
                      errors.message && touched.message ? " form-error" : ""
                    }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                  />
                  {errors.message && touched.message && (
                    <span className="form-error-message">{errors.message}</span>
                  )}
                  <div className="recaptcha-container">
                    <div>This form is protected by reCAPTCHA</div>
                    <div>
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy
                      </a>
                      <a
                        href="https://policies.google.com/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms
                      </a>
                    </div>
                  </div>
                  <div className="padding-top-2">
                    <button
                      className={`btn full-width form-btn${
                        disabled ? " disabled" : ""
                      }`}
                      type="submit"
                      disabled={disabled}
                    >
                      {isSubmitting ? "SUBMITTING..." : "SUBMIT"}
                    </button>
                  </div>
                </form>
              </div>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY}>
      <ContactForm />
    </GoogleReCaptchaProvider>
  );
};
